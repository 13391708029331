<template>
  <div class="persons page">
    <div class="header">
      <a @click="$router.go(-1)" class="go-back"><i class="fas fa-chevron-left"></i></a>
      <span class="header__title">{{categoryTitle}}</span>
      <div class="header__right search" :class="{active: activeSearch}">
        <button class="btn persons__header-btn search-button" @click="search"><i class="fas fa-search"></i></button>
        <input type="text" class="search-input" ref="search" v-model="searchValue">
        <button class="btn persons__header-btn search-close" @click="search"><i class="fas fa-times"></i></button>  
      </div>
    </div>
    <div class="container">
      <div class="persons-list">
        <router-link 
          to="/person" 
          class="person-link" 
          v-for="person in persons"
        >
          <div class="person" @click="$emit('openPerson', person.id)">
            <div class="person__image">
              <img :src="person.img" alt="" v-if="person.img">
              <img src="@/assets/images/empty-img.png" alt="" class="empty-image" v-else>
            </div>
            <div class="person__info">
              <span class="person__name">{{person.title}}</span>
              <span class="person__dob" v-if="person.born">{{person.born}} <i v-if="person.died">-</i> {{person.died}}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  props: ['categoryId', 'categoryName'],
  data() {
    return {
      persons: null,
      activeSearch: false,
      searchValue: '',
      categoryTitle: '',
    }
  },
  methods: {
    search() {
      if(!this.activeSearch) {
        this.activeSearch = true
        this.$refs.search.focus();
      } else {
        this.activeSearch = false
      }
    }
  },
  mounted() {
    if (this.categoryId) {
      localStorage.setItem('categoryId', this.categoryId);
      localStorage.setItem('categoryName', this.categoryName);
      
      this.categoryTitle = this.categoryName

      fetch('https://person.lezgikim.ru/api.php?do=getPostList&key=156dfdd335hjkodS&CatID=' + this.categoryId)
      .then(response => response.json())
      .then(json => {
        this.persons = json
      })
    } else {
      var categoryId = localStorage.getItem('categoryId')
      this.categoryTitle = localStorage.getItem('categoryName')

      fetch('https://person.lezgikim.ru/api.php?do=getPostList&key=156dfdd335hjkodS&CatID=' + categoryId)
      .then(response => response.json())
      .then(json => {
        this.persons = json
      })
    }
  },
  updated() {
    console.log(this.searchValue)
  }

}
</script>

<style lang="scss">
  @import "../assets/css/home.scss";
</style>
